.section-hero {
  background: radial-gradient(circle at 10% 20%, rgb(251, 221, 19) 0.1%, rgb(255, 153, 1) 90%);
}

.section-content {
  height: 100%;
  margin: 0 auto;
  max-width: 800px;
  text-align: center;

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 20px;
  }
}