.footer {
  align-items: center;
  color: #ccc;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  padding: 20px;
  border-top: 1px solid #f3f3f3;
  
  @media (max-width: 800px) {
    flex-direction: column-reverse;
    padding-bottom: 50px;
  }
}

.footer-legal {
  color: #ccc;

  @media (max-width: 800px) {
    padding-top: 10px;
  }
}

.footer-contact > form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  
  @media (max-width: 800px) {
    flex-direction: column;
  }
}

.footer-contact-signup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 800px) {
    flex-direction: row;
    display: flex;
    align-items: center;
  }
}

#email {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 300px;
  margin-right: 5px;
  color: orange;
  font-size: 16px;

  @media (max-width: 800px) {
    margin-bottom: 10px;
    margin-top: 10px;
    width: auto;
  }

  &::placeholder { 
    color: #ccc;
  }
}

button[type="submit"] {
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: orange;
  color: #fff;
  cursor: pointer;

  @media (max-width: 800px) {
    width: 100px;
  }
}

button[type="submit"]:hover {
  background-color: orange;
}