.app {
  text-align: center;
  color: #2d2d2d;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}

.app-content {
  max-width: 800px;
}