.landing-page {
  color: white;
  text-align: left;
  flex-direction: column;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 0px 20px;

  h1 {
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 80px;
  }
  
  h2 {
    font-size: 40px;
  }
  
  h3 {
    font-size: 24px;
    font-weight: normal;
  }
}

